@import '~materialize-css/sass/components/_color-variables.scss';

$primary-color: color('orange', 'darken-2') !default;
$secondary-color: color('red', 'darken-2') !default;

$text-color: color('grey', 'lighten-3') !default;
$page-bg-color: color('grey', 'darken-4') !default;
$card-bg-color: lighten($page-bg-color, 3%) !default;

$border-color: rgba(255, 255, 255, 0.1);

$sidenav-bg-color: $card-bg-color;
$sidenav-font-color: $text-color;

$input-disabled-solid-color: color('grey', 'darken-3');
$button-disabled-background: $input-disabled-solid-color;
$input-disabled-color: color('grey', 'darken-1');
$button-disabled-color: $input-disabled-color;
$input-border-color: $border-color;
$placeholder-text-color: rgba(255, 255, 255, 0.3);

$collection-border-color: $border-color;
$collection-bg-color: $card-bg-color;
$collection-active-bg-color: lighten($card-bg-color, 2%);
$collection-hover-bg-color: $collection-active-bg-color;

$sidenav-z: 999;

@import '~materialize-css/sass/materialize.scss';

.row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal {
  background-color: $card-bg-color;

  .modal-footer {
    background-color: darken($card-bg-color, 2.5%);

    .btn, button {
      color: darken($text-color, 2%);
      background-color: darken($card-bg-color, 5%);
      margin-left: 1rem;
    }
  }
}

.block {
  display: block;
  width: 100%;
}

.larger {
  font-size: 1.3rem;
}

.container-large {
  padding: 1.5rem 0.75rem;
  margin: 0 auto;
  max-width: 1300px;
}

.collection .collection-item.avatar.avatar {
  min-height: auto;
}

.collection {
  &.with-header > .collection-header {
    background-color: $primary-color-dark;
    border-bottom: none;

    h4 {
      font-size: inherit;
      margin: 0;
    }
  }
}

.collection.no-border {
  border: none;
}
.full-splash {
  $background-opacity: 0.1;
  min-height: 100vh;
  position: relative;

  background:
          linear-gradient(135deg,
                  rgba($primary-color, 0.6) 0%,
                  rgba($primary-color, 0.1) 100%
          ),
          linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4)),
          linear-gradient(60deg,
                  rgba($primary-color-dark, 0.1) 0%,
                  rgba($primary-color-dark, 0.1) 50%,
                  rgba($primary-color-dark, 0.0) 50.1%
          ),
          black;

  form, .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    margin: 0 auto;
    max-width: 300px;

    h1 {
      font-weight: 300;
      font-size: 2.5rem;
    }

    img {
      width: 150px;
      opacity: 0.9;
      margin: 0 auto 1.5rem auto;
      display: block;
    }

    .card {
      background-color: rgba(darken($primary-color-dark, 25%), 0.75);
    }
  }
}

.primary-text {
  color: $primary-color !important;
}

.primary {
  background-color: $primary-color !important;
}

.primary-dark {
  background-color: $primary-color-dark !important;
}

.primary-light {
  background-color: $primary-color-light !important;
}

input, textarea {
  color: $text-color;
}

.primary-light-text {
  color: $primary-color-light !important;
}

.primary-dark-text {
  color: $primary-color-dark !important;
}

table.attributes {
  tr {
    border: 1px solid $border-color;
    border-left: none;
    border-right: none;
  }

  td, th {
    padding: 0.5rem 0;
  }
}
