@import 'scss/materialize';
@import './App';
@import 'scss/NavBar';
@import "scss/SearchBar";
@import 'scss/Collection';

html {
  background-color: $page-bg-color;
  color: $text-color;
}

.sidenav {
  margin-top: $navbar-height;
  z-index: $sidenav-z;
  $background-opacity: 0.6;

  .background {
    background-color: $primary-color-dark;
    background:
            black
            radial-gradient(circle at 20px -10px,
                    rgba($primary-color, $background-opacity) 0%,
                    rgba($primary-color, $background-opacity) 60%,
                    rgba($primary-color, $background-opacity + 0.2) 60.1%
            );

    img {
      height: 100%;
      float: right;
      padding: 1rem;
      opacity: 0.2;
    }
  }

  .subheader {
    color: darken($sidenav-font-color, 50%);
  }

  .divider {
    background-color: lighten($sidenav-bg-color, 10%);
  }

  li > a > i.material-icons.prefix {
    color: darken($sidenav-font-color, 50%);
  }

  li > a.primary-dark > i.material-icons.prefix {
    color: $primary-color-light;
  }

  li > a:hover {
    background-color: lighten($sidenav-bg-color, 5%);
  }
}

.preloader-wrapper.block {
  margin: 0 auto;
}